import * as Sentry from '@sentry/vue';
import * as Integrations from '@sentry/integrations';
import { BrowserTracing } from "@sentry/tracing";
import RequestError from 'JIX/errors/RequestError.js';

const stash = window.Stash['sentry'];
const commonStash = window.Stash.common;

const whitelistPaths = [
    window.location.pathname.replace(/\/$/, "/?") + "$",
    "/js/",
    "/res/",
    "/api/"
];
const siteurl = window.location.protocol + "//" + window.location.host.replace(/\./g, '\\.');

const config = {
    app: [], // Apps are added dynamically via html/src/lib/vue-sentry.js
    ...stash.config,
    autoSessionTracking: false,
    integrations: [
        new Integrations.ExtraErrorData(),
        new BrowserTracing({
            beforeNavigate: (context) => {
                let name = context.name;
                name = name.replace(/^(\/virksomhed\/).*/, '$1*');
                name = name.replace(/^(\/jobsoegning)\/.*/, '$1');
                name = name.replace(/^(\/tjek-din-loen)\/.*/, '$1');
                name = name.replace(/^(\/jobannonce\/).*/, '$1*');
                name = name.replace(/^(\/blogs\/job\/).*/, '$1*');
                return {
                    ...context,
                    name,
                };
            },
        }),
    ],
    allowUrls: [
        /^webpack-internal:\/\//,
        ...whitelistPaths.map(path => new RegExp(siteurl + path))
    ],
    denyUrls: [
        "/res/ckeditor",
        /\/content\/.*\/preview/,
        '/reportage/',
    ],
    ignoreErrors: [
        'Et JavaScript-object var ventet',
        'Ett JavaScript-objekt förväntas',
        'JavaScript object expected',
        'Programkoden kan ikke køres fra et frigjort script',
        'Can\'t execute code from a freed script',
        'Illegal invocation',
        'Blocked a frame with origin',
        'NetworkError when attempting to fetch resource',
        'Failed to initialize WebGL',
    ],
    beforeSend(event, hint) {
        if (window.location.protocol === 'file:') {
            console.warn('Local file - event ignored');
            return null;
        }
        const cutoff = 3 * 24 * 60 * 60 * 1000; // days in ms
        if (stash.page_load_time && (new Date() - stash.page_load_time) > cutoff) {
            console.warn('Event ignored on out-of-date page');
            return null;
        }
        if (window.jix_script_errors && window.jix_script_errors.length > 0) {
            console.warn('Event ignored because of JavaScript load errors');
            return null;
        }
        const error = hint.originalException;
        if (error instanceof RequestError && error.status === 0) {
            // Request was blocked or aborted – ignore
            return null;
        }
        return event;
    },
};

Sentry.init(config);
Sentry.setExtras(stash.extra);
Sentry.setTags(stash.tags);
Sentry.setTag('lang', commonStash.lang);
if (commonStash.companyuserid) {
    Sentry.setUser({ id: `CU#${commonStash.companyuserid}` });
} else if (commonStash.userid) {
    Sentry.setUser({ id: `U#${commonStash.userid}` });
}

window.Sentry = Sentry;
